import { useEffect } from 'react';

import {
  getFullRouteUrl,
  removeLanguageAndCountryPrefixFromPath,
} from '@app/services/routeHelpers';

import { Context } from '@app/types/common';

import useI18nUrlData, {
  getI18nUrlDataFromState,
} from '@app/hooks/helpers/useI18nUrlData';
import useOnboardingTrigger from '@app/hooks/helpers/useOnboardingTrigger';

import { redirectToPath } from '@app/services/initialisation';

import BodyColor from '@app/components/BodyColor';
import HeaderContainer from '@app/components/layout/HeaderContainer';
import SeoMetaTags from '@app/components/SeoMetaTags';
import StandardHeadTags from '@app/components/StandardHeadTags';

const Login = () => {
  const onboardingTrigger = useOnboardingTrigger();
  const i18nUrlData = useI18nUrlData();

  useEffect(() => {
    onboardingTrigger('loginNew');
  }, []);

  const canonicalUrl = getFullRouteUrl({
    url: '/login',
    i18nUrlData,
  });

  return (
    <>
      <BodyColor themeColor="midBackground" />
      <StandardHeadTags />
      <SeoMetaTags canonicalUrl={canonicalUrl} />
      <HeaderContainer />
    </>
  );
};

Login.getInitialProps = async (ctx: Context) => {
  const { store, query } = ctx;
  const currentState = store.getState();
  const isAuthenticated = currentState?.user?.isAuthenticated;

  let returnTo = '/showing';
  if (typeof query?.return_to === 'string') {
    returnTo = removeLanguageAndCountryPrefixFromPath(query.return_to);
  }

  if (isAuthenticated) {
    redirectToPath(
      getFullRouteUrl({
        url: returnTo,
        i18nUrlData: getI18nUrlDataFromState(currentState),
      }),
      ctx,
    );
  }

  return {};
};

export default Login;
