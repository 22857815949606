import { css, Global } from '@emotion/react';

import { color as themeColors, ColorKey } from '@app/themes/mubi-theme';

type BodyColorProps = {
  themeColor: ColorKey;
};

const BodyColor = ({ themeColor }: BodyColorProps) => (
  <Global
    styles={css`
      body {
        background-color: ${getThemeColorFromNestedThemeColors(
          themeColor,
          themeColors,
        )};
      }
    `}
  />
);

const getThemeColorFromNestedThemeColors = (
  colorString: string,
  nestedObject: typeof themeColors,
) =>
  colorString.split('.').reduce((prevObject, i) => prevObject[i], nestedObject);

export default BodyColor;
